import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import { LocalizationServices } from './LocalizationServices';
import { AppUserClient } from './Services';
import router from '@/router';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { getViewWithServices } from '@/utils';

class _LoginServices {
    
    // CheckLogin(component){
    //     var loggedUser = StorageServices.getLoggedUser();
	// 	if(loggedUser && loggedUser.token){
    //         this.LoginCallback(loggedUser.token, component)
    //     } else
    //         router.replace('/shareposition');
    // }

    LoginCallback(token: string, refreshToken: string, selectedLanguage: string, nextRoute: string = ""): Promise<void>{
        var prom = new Promise<void>((resolve, reject) => {
            if(!token){
                LoginServices.logout();
                reject();
                return;
            }
            
            StorageServices.setNewTokens(token, refreshToken);
            this.tokenUpgrade(selectedLanguage, nextRoute);
        })
        
        return prom;
    };

    RegisterNewUser(registerModel: OM.LanguageCredential, updateUserInfoModel: OM.UpdateUserInfoVM, hasToReceiveWrist: boolean, nextRoute: string = "") {
        var prom = new Promise<string>((resolve, reject) => {

            AppUserClient.register(registerModel)
            .then(x => {
                StorageServices.setNewTokens(x.token, x.refreshToken);
                AppUserClient.tokenUpgrade()
                .then( x => {
                    var loggedUser = x;
                    StorageServices.setLoginToken(x.token);
                    StorageServices.setLoggedUser(loggedUser);
                    StorageServices.setNewTokens(x.token, x.refreshToken);
                    
                    updateUserInfoModel.identifier = x.identifier;
                    AppUserClient.updateUserInfo(updateUserInfoModel)
                    .then(x => {
                        loggedUser.completeName = updateUserInfoModel.name + " " + updateUserInfoModel.surname;
                        loggedUser.isOwner = updateUserInfoModel.type == "O";
                        store.state.loggedUser = loggedUser;
                        StorageServices.setLoggedUser(loggedUser);

                        if(!hasToReceiveWrist) {
                            if(nextRoute) {
                                router.push(nextRoute);
                                resolve("");
                            }
                            else {
                                router.push('/profile');
                                resolve("");
                            }
                        }
                        else {
                            AppUserClient.setWristTaken(loggedUser.identifier)
                            .then(x => {
                                resolve(loggedUser.identifier);
                            })
                        }
                    })
                })
            })

        })
        
        return prom;
    }

    private tokenUpgrade(selectedLanguage: string = "it-IT", nextRoute: string = ""){
        var prom = new Promise<void>((resolve, reject) => {
            AppUserClient.tokenUpgrade()
            .then( x => {
                var loggedUser = x;
                
                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
                
                StorageServices.setNewTokens(x.token, x.refreshToken);

                if(!selectedLanguage)
                    selectedLanguage = "it-IT";
                    
                AppUserClient.changeLanguageAndGetToken(selectedLanguage)
                .then(x => {
                    store.state.selectedLanguage = selectedLanguage;
                    loggedUser.preferredCulture = selectedLanguage;
                    StorageServices.setLoggedUser(loggedUser);

                    if(store.state.loggedUser.role == VM.AppRole.InternalUser)
                        router.push("/dashboard");
                    else if(nextRoute)
                        router.push(nextRoute);
                    else
                        router.push("/profile");
                        
                    resolve();
                })
            })
            .catch( err => {
                LoginServices.logout();
                reject();
            });
        })
        
        return prom;
    }

    logout(isHostess: boolean = false) {
        let prom = new Promise<void>((resolve, reject) => {
            //Resetto le credenziali dell'autenticazione
            CommonServices.destroyToken();
            StorageServices.setLoginRefreshToken("");
            this.resetLoggedUser();

            //Chiudo tutte le modali
            // var viewWithServices = getViewWithServices();
            // viewWithServices.$opModal.closeAll();

            //Nascondo il loader
            store.state.showSpinner = 0;

            //Rimando alla login
            if(isHostess) {
                router.replace('/hostess');
                resolve();
            }
            else {
                router.replace('/');
                resolve();
            }

        })
        return prom;
    }

    private resetLoggedUser() {
        var loggedUser = new OM.LoggedUserVM();
        loggedUser.preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();