import { StorageServices } from "./services/StorageServices";
import router from "./router";
import route from "./router";
import store from "./store";
import * as OM from "./Model";

let viewWithServices = null;

export function assignViewWithServices(viewWithServicesComponent){
    viewWithServices = viewWithServicesComponent;
}

export function getViewWithServices(){
    return viewWithServices;
}

export function routerReplaceAndPush(replaceUrl: string, pushUrl: string){
    router.replace(replaceUrl)
    .then( () => {
        router.push(pushUrl);
    }).catch( () => {
        console.error("router replace error!");
    })
}

export function removeSpaces(text: string) : string {
    if(!text)
        return text;

    var temp = text.trim();

    while(temp.indexOf("  ") != -1)
        temp = temp.replace("  ", " ").trim();

    return temp;
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized)
        localized = new OM.LocalizedValue();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
} 

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function removeBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "hidden";
}
export function addBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "auto";
}

export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function getFileFromUri(uri: string): Promise<File>{
    if(uri.indexOf('://') === -1) 
        uri = "file://" + uri;

    let prom = new Promise<File>((resolve, reject) => {
        (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
            fileEntry.file((resFile) => {
                resolve(resFile);
            });
        }, err => {
            console.log(err)
        })
    });

    return prom;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if(!file){
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        if(!window.cordova){
            var url = URL.createObjectURL(file);
            resolve(url);
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}