import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from '@/config';
import router from '@/router';
import store from '@/store';
import { LoginServices } from './LoginServices';
import { AppUserClient } from './Services';
import * as OM from '@/Model';
import { StorageServices } from './StorageServices';
import { getViewWithServices } from '@/utils';

class _CommonServices {
    baseUrl = baseUrl;

    showSpinner(){
        store.state.showSpinner++;
    };
    hideSpinner(){
        store.state.showSpinner--;
    };

    unauthorizedCb = () => {};
    
    private axios: AxiosInstance;
    defaultGet<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        url = replaceUrl(url, params);

        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.get<T>(url, req)
            .then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }
    defaultPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }

    async uploadFileToUrl<T = any>(url: string, file: File | string, params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        
        if(file)
            params["file"] = file;

        if (params) {
            data = await this.convertModelToFormData(params);
        }

        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        
        if(_showSpinner)
            this.showSpinner();

        let prom = new Promise<T>((resolve, reject) => {
            Axios.post<T>(url, data, config).then(x => {
                if(x && x.data)
                    resolve(x.data);
                else
                    resolve(<any>x);
            })
            .catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }

    async uploadFilesToUrl<T = any>(url: string, file: File[], params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        
        if(file)
            params["files"] = file;
    
        if (params) {
            data = await this.convertModelToFormData(params);
        }
    
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        
        if(_showSpinner)
            this.showSpinner();
    
        let prom = new Promise<T>((resolve, reject) => {
            Axios.post<T>(url, data, config).then(x => {
                if(x && x.data)
                    resolve(x.data);
                else
                    resolve(<any>x);
            })
            .catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }

    setAuthToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('authtoken', token);
    }
    destroyToken() {
        this.axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('authtoken');
    }

    refreshPromise: Promise<any> = null;

    constructor() {
        this.axios = Axios;
        let token = window.localStorage.getItem('authtoken');
        this.axios.interceptors.response.use((response: any) => {
            return response;
        }, (error) => {
            const originalConfig = error.config;

            if (error.response.status == 401) {
                LoginServices.logout();
            }
            else if (error.response.status === 499 && !originalConfig._retry) {
                originalConfig._retry = true; //evita i loop
                if(this.refreshPromise){
                    return new Promise((resolve, reject) => {
                        this.refreshPromise
                        .then(x => {
                            originalConfig.headers.Authorization = "Bearer " + x.token;
                            resolve(this.axios(originalConfig));
                            return x;
                        }).catch( err => {
                            reject(err);
                            return err;
                        })
                    })
                } else {

                    var loggedUser = store.state.loggedUser;
                    var tokens: OM.AuthResponse = {
                        refreshToken: loggedUser.refreshToken,
                        token: loggedUser.token,
                        refreshTokenExpiryTime: ""
                    };

                    return new Promise((resolve, reject) => {
                        this.refreshPromise = AppUserClient.refreshToken(tokens)
                        .then(refreshTokenResponse => {
                            var token = refreshTokenResponse.token;
                            
                            StorageServices.setNewTokensByAuthResponse(refreshTokenResponse);
                            
                            originalConfig.headers.Authorization = "Bearer " + token;
                            
                            resolve(this.axios(originalConfig));
                            this.refreshPromise = null;
                            return refreshTokenResponse;
                        }).catch( err => {
                            this.refreshPromise = null;
                            LoginServices.logout();
                            
                            var data = {
                                Message: getViewWithServices().$localizationService.getLocalizedValue("app_LaTuaSessioneEScaduta", "La tua sessione è scaduta! <br />  Effettua di nuovo l'accesso."),
                            };
                            return Promise.reject(data);
                        })
                    })
                }
            }
            else if (error.response.status === 499 && originalConfig._retry) {
                LoginServices.logout();
                var data = {
                    Message: getViewWithServices().$localizationService.getLocalizedValue("app_LaTuaSessioneEScaduta", "La tua sessione è scaduta! <br />  Effettua di nuovo l'accesso."),
                };
                return Promise.reject(data);
            }
            else {
                if(error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
        if (token){
            this.setAuthToken(token);
        }
    }

    private async convertModelToFormData(data = {}, form = null, namespace = '') {
        let files = {};
        let model = {};
        for (let propertyName in data) {
            if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File && window.cordova){
                model[propertyName] = data[propertyName].localURL;
            } 
            else if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) 
            {
                files[propertyName] = data[propertyName];
            } 
            else
                model[propertyName] = data[propertyName]
        }

        let formData = form || new FormData();
        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) 
            continue;
            
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date){
                formData.append(formKey, model[propertyName].toISOString());
            } else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName]);
            } else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach(async (element, index) => {
                    let tempFormKey = `${formKey}[${index}]`;
                    if(element instanceof File){
                        tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                        formData.append(tempFormKey, element);
                    } else if(typeof element == "string" && window.cordova){
                        if(element.startsWith("file:///") || element.startsWith("cdvfile://")){
                            tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                            await this.addFile(formData, element, tempFormKey);
                        }
                    } else if (typeof element === 'object') {
                        this.convertModelToFormData(element, formData, tempFormKey);
                    } else {
                        formData.append(tempFormKey, element.toString());
                    } 
                });
            } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)){
                this.convertModelToFormData(model[propertyName], formData, formKey);
            } else {
                if(typeof model[propertyName] == "string" && window.cordova){
                    if(model[propertyName].startsWith("file:///")  || model[propertyName].startsWith("cdvfile://")){
                        await this.addFile(formData, model[propertyName], formKey);
                    } else {
                        formData.append(formKey, model[propertyName].toString());
                    }
                } else {
                    formData.append(formKey, model[propertyName].toString());
                }
            }
        }
        
        for (let propertyName in files) {
            if (files.hasOwnProperty(propertyName)) {
                formData.append(propertyName, files[propertyName]);
            }
        }
        return formData;
    }
    private addFile(form, fileUri, key) {
        if(fileUri.startsWith("cdvfile://"))
            (<any>window).resolveLocalFileSystemURL(fileUri, (entry) => {
                fileUri = entry.toURL();
            })
            
        let self = this;
        let promise = new Promise((resolve, reject) => {
            
            (<any>window).resolveLocalFileSystemURL(fileUri, function (fileEntry) {
                fileEntry.file(function (file) {
                    var reader = new FileReader();
                    reader.onloadend = function (fileReadResult: any) {
                        var data = new Uint8Array(fileReadResult.target.result);
                        var blob = self.createBlob(data, file.type || self.getMimeType(file.name));
                        form.append(key, blob, file.name);
                        resolve(null);
                    };
                    
                    reader.onerror = function (fileReadResult) {
                        reject(fileReadResult);
                    };
                    reader.readAsArrayBuffer(file);
                });
            }, (err) => {
                console.log(err)
            });
            
        })
        return promise;
    }

    private getMimeType(fileName) {
        var extension = fileName.split('.').pop();
        return this.mimetypes.hasOwnProperty(extension) ? this.mimetypes[extension] : undefined;
    }

    private mimetypes = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png'
    };

    private createBlob(data, type) {
        var r;
        r = new window.Blob([data], {type: type});
        return r;
    }
}

function replaceUrl(url: string, params?: any): string {

    if(!params || url.indexOf("{") == -1)
        return url;

    var beginIndex = url.indexOf("{");
    var endIndex = url.indexOf("}");

    var property = url.substring(beginIndex + 1, endIndex);
    var value = params[property];


    url = url.substring(0, beginIndex) + value + url.substr(endIndex + 1);
    delete params[property];

    return replaceUrl(url, params);
}

export let CommonServices = new _CommonServices();