import moment from 'moment';
import { LocalizationServices } from './services/LocalizationServices';
import { StorageServices } from './services/StorageServices';
import store from './store';
import { getUniversalDate } from './utils';
import * as OM from "@/Model";

export default {

    date(value: any, _format: string, _showTime: boolean) {
        if (value) {
            value = getUniversalDate(value);
            let format;
            if(!_format){
                format = 'YYYY MMMM DD';
                if(_showTime)
                    format += 'HH:mm:ss';
            } else {
                format = _format;
            }

            var preferredCulture = StorageServices.getLoggedUser().preferredCulture;
            return moment(value).locale(preferredCulture).format(format);
        }
    },

    time(value: any, _format: string, _showTime: boolean) {
        if (value) {
            let format;
            if(!_format){
                format = 'HH:mm';
            } else {
                format = _format;
            }

            var preferredCulture = StorageServices.getLoggedUser().preferredCulture;
            return moment(value).locale(preferredCulture).format(format);
        }
    },

    chatDate(value: any) {
        if(!value)
            return;

        var date = new Date(value);
        var today = new Date();
        
        var startString = "";
        var format = "HH:mm";
        
        //Se è stato inviato oggi
        if(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_Oggi", "Oggi", ", ");
        }
        //Se è stato inviato ieri
        else if(date.getDate() == today.getDate() - 1 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_ieri", "Ieri", ", ");
        }
        //se è stato inviato questa settimana
        else if(date.getDate() >= today.getDate() - 6 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            format = "dddd, HH:mm";
        } else {
            format = "DD/MM/YYYY, HH:mm";
        }

        var preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        return startString + moment(date).locale(preferredCulture).format(format);
    },

    notificationDate(value: any) {
        if(!value)
            return;

        var date = new Date(value);
        var today = new Date();
        
        var startString = "";
        var format = "HH:mm";
        
        //Se è stato inviato oggi
        if(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_Oggi", "Oggi", ", ");
        }
        //Se è stato inviato ieri
        else if(date.getDate() == today.getDate() - 1 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            startString = LocalizationServices.getLocalizedValue("app_ieri", "Ieri", ", ");
        }
        //se è stato inviato questa settimana
        else if(date.getDate() >= today.getDate() - 6 && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
            format = "dddd, HH:mm";
        } else {
            format = "DD/MM/YYYY, HH:mm";
        }

        var preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        return startString + moment(date).locale(preferredCulture).format(format);
    },

    currency(value: any, decimals: number) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    age(value: any) {
        if(!value)
            return "";
        
        var date = getUniversalDate(value);
        var today = new Date();

        var age = today.getFullYear() - date.getFullYear();
        var m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age--;
        }

        return age;
    },

    kmFromMeters(value: number) {
        var preferredCulture = store.state.loggedUser.preferredCulture;
        var km = value / 1000;

        var options = {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2, 
        }
        
        var format = Intl.NumberFormat(preferredCulture, options);
        var kmText = LocalizationServices.getLocalizedValue("app_Km", "Km");
        var kmValue = format.format(km);

        var result = kmValue + " " + kmText;

        return result;
    },

    getLocation(address: OM.Address){
        var city = address.locality;
        var countryCode = address.countryCode;
    
        return city + ", " + countryCode;
    },

    getChatTextFormatted(text: string){
        var formattedText = text.replaceAll("\n", "<br />");
        return formattedText;
    },

    cutNameTooLong(name: string){

        if(name.length <= 12)
            return name;

        return name.substring(0, 12) + "...";
    },

    formatTimeFromSeconds(time: number) {
        var timeArr: any = [
            Math.floor((((time % 31536000) % 86400) % 3600) / 60),
            ((((time % 31536000) % 86400) % 3600) % 60)
        ];

        timeArr[1] = Math.floor(timeArr[1]);

        for (var i = 0; i < timeArr.length; i++) {
            if (timeArr[i] < 10) {
                timeArr[i] = '0' + timeArr[i];
            };
        }
        return timeArr[0] + ':' + timeArr[1];
    }
}