import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "apriliaContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner_container = _resolveComponent("spinner-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_spinner_container),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: "opacity",
              class: "duration04 no_leave_animation",
              appear: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { style: 'height: 100%; overflow-y: auto;' }))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_modals_container)
  ]))
}