
import { Options, Vue } from 'vue-class-component';
import { QrReader, QrStream, QrCapture, QrDropzone } from 'vue3-qr-reader';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
        QrStream,
        QrCapture,
        QrDropzone,
        QrReader
    }
})
export default class QrScan extends Vue {

    @Prop() callback: any;

}
