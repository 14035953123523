import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f5a97fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center mx-2" }
const _hoisted_2 = {
  class: "valuesSpan",
  ref: "days"
}
const _hoisted_3 = { class: "text-center mx-2" }
const _hoisted_4 = {
  class: "valuesSpan",
  ref: "hours"
}
const _hoisted_5 = { class: "text-center mx-2" }
const _hoisted_6 = {
  class: "valuesSpan",
  ref: "minutes"
}
const _hoisted_7 = { class: "text-center mx-2" }
const _hoisted_8 = {
  class: "valuesSpan",
  ref: "seconds"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex w-100 countdown", _ctx.showOnlySeconds ? 'justify-content-center' : 'justify-content-between'])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, "--", 512),
      _createVNode(_component_localized_text, {
        localizedKey: "app_giorni",
        text: "Giorni",
        class: "labelSpan"
      })
    ], 512), [
      [_vShow, !_ctx.showOnlySeconds]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, "--", 512),
      _createVNode(_component_localized_text, {
        localizedKey: "app_ore",
        text: "Ore",
        class: "labelSpan"
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, "--", 512),
      _createVNode(_component_localized_text, {
        localizedKey: "app_minuti",
        text: "Minuti",
        class: "labelSpan"
      })
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", _hoisted_8, "--", 512),
      _createVNode(_component_localized_text, {
        localizedKey: "app_secondi",
        text: "Secondi",
        class: "labelSpan"
      })
    ], 512), [
      [_vShow, _ctx.showOnlySeconds]
    ])
  ], 2))
}