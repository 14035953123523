export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export class PhoneNumber {
    number: string;
    prefix: string;

    constructor() {
        this.number = "";
        this.prefix = "";
    }
}

export class ReportQuestion {
    index: number;
    motivation: string;

    constructor() {
        this.index = -1;
        this.motivation = "";
    }
}

export enum EditPhotoAction {
    changephoto,
    editqa,
    delete
}

export class MatchQuestionAnswerVM {
    questionIndex: number;
    answer: string;
    imageUrl: string;
    affinity: number;

    constructor() {
        this.questionIndex = 0;
        this.answer = "";
        this.imageUrl = "";
        this.affinity = 0;
    }
}

export class FakeProfileVM {
    name: string;
    age: number;
    imageUrl: string;
    questionKey: string;
    questionValue: string;

    constructor() {
        this.name = "";
        this.age = 0;
        this.imageUrl = "";
        this.questionKey = "";
        this.questionValue = "";
    }
}

export var PremiumPurchasedProvider = {
    Android: "Android",
    Stripe: "Stripe",
    Apple: "Apple"
}

export var defaultLanguage = "en-US";

export class GoogleAutocompletePredictionResponseVM {

    description: string;
    matched_substrings : GooglePredictionSubstringVM[];
    place_id: string;
    structured_formatting: GoogleStructuredFormattingVM[];
    terms: GooglePredictionTermVM[];
    types: string[];
    distance_meters?: number

    constructor(){
        this.description = "";
        this.matched_substrings = [];
        this.place_id = "";
        this.structured_formatting = [];
        this.terms = [];
        this.types = [];
        this.distance_meters = null;   
    }
}

export class GooglePredictionSubstringVM {

    length: number;
    offset : number; 

    constructor(){
        this.length = 0;
        this.offset = 0;
    }
}

export class GoogleStructuredFormattingVM {

    main_text: string;
    main_text_matched_substrings : GooglePredictionSubstringVM[];
    secondary_text: string; 

    constructor(){
        this.main_text = "";
        this.main_text_matched_substrings = [];
        this.secondary_text = "";
    }
}

export class GooglePredictionTermVM {

    offset : number; 
    value: string;

    constructor(){
        this.offset = 0;
        this.value = "";
    }
}

export class GoogleFilterAddressVM {

    changed: boolean;

    placeId: string;
    description: string;
    addressFixed: boolean;

    latitude : number;
    longitude : number;

    constructor(){
        this.changed = false;
        this.placeId = "";
        this.description = "";
        this.addressFixed = false;

        this.latitude = 0;
        this.longitude = 0;
    }
}

export var ExperiencePeriodVM = {
    weekend: "weekend",
    week: "week",
    month: "month"
}

export var ExperienceTypeVM = {
    active: "active",
    pending: "pending",
    past: "past",
    draft: "draft"
}

export class RegisterVM {
    name: string;
    surname: string;
    email: string;
    password: string;
    isMaschio: boolean;

    constructor() {
        this.name = "";
        this.surname = "";
        this.email = "";
        this.password = "";
        this.isMaschio = false;
    }
}

export var AppRole = {
    Admin: "admin",
    AppUser: "user",
    InternalUser: "internaluser",
}