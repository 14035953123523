import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06c41728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["tabindex", "readonly", "required"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "select_overlay",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)), ["stop","prevent"]))
    }),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
      tabindex: _ctx.tabIndex,
      readonly: _ctx.readonly,
      required: _ctx.required
    }, [
      (_ctx.placeholderKey && _ctx.placeholderValue)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            hidden: "",
            disabled: "",
            value: _ctx.getDefaultValue
          }, _toDisplayString(_ctx.$localizationService.getLocalizedValue(_ctx.placeholderKey, _ctx.placeholderValue, _ctx.placeholderAfterText, _ctx.placeholderPrevText)), 9, _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localList, (item, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: item.identifier
        }, [
          (_ctx.showIdentifier)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.identifier), 1))
            : (_openBlock(), _createBlock(_component_localized_text, {
                key: 1,
                class: "white",
                localizedKey: 'app_' + item.text,
                text: item.text
              }, null, 8, ["localizedKey", "text"]))
        ], 8, _hoisted_4))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.localValue]
    ])
  ]))
}