import { LocalizationServices } from './services/LocalizationServices';
import * as OM from '@/Model';

export const date = function (el, binding, vnode, prevVnode) {
    if (el.tagName.toLowerCase() != "input") {
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if (el.getAttribute("type") != "date") {
        console.error('v-date directive must be used on input with type="date"');
        return;
    }

    let modelDirectiveIndex = vnode.dirs.findIndex(x => x.value && (isValidDate(x.value) || isValidStringDate(x.value)));
    if(modelDirectiveIndex == -1){
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = vnode.dirs[modelDirectiveIndex].value;
    if(val){
        (<HTMLInputElement>el).value = validateDate(val);
        var item = (<HTMLInputElement>el).attributes.getNamedItem("placeholder");

        if(item){
            item.value = "";
            (<HTMLInputElement>el).attributes.setNamedItem(item);
        }
    }
};

function validateDate(inputDate: string){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        return years +  "-" + months + "-" + days;
    }
}

function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}

function isValidStringDate(d: any) {
    return typeof(d) == typeof("") && Date.parse(d);
}

export let localizedPlaceholderDirective = function (el, binding, vnode, prevVnode) {

    var key = binding.value["key"];
    var value = binding.value["value"];

    if (!key || !value) {
        console.error('v-localizedPlaceholder need key and value');
        return;
    }

    var prevText = binding.value["prevText"];
    var afterText = binding.value["afterText"];

    var text = LocalizationServices.getLocalizedValue(key, value, afterText, prevText);

    (<any>el).placeholder = text;
}

export const longpress = function (el, binding, vnode, prevVnode) {

    // Make sure expression provided is a function
    if (typeof binding.value !== 'function') {
        // Fetch name of component
        const compName = vnode.context.name
        // pass warning to console
        let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
        if (compName) { warn += `Found in component '${compName}' ` }

        console.warn(warn)
    }

    // Define variable
    let pressTimer = null

    // Define funtion handlers
    // Create timeout ( run function after 1s )
    let start = (e) => {

        if (e.type === 'click' && e.button !== 0) {
            return;
        }

        if (pressTimer === null) {
            pressTimer = setTimeout(() => {
                // Run function
                console.log("pippoooooo")
                console.log(el)
                console.log(binding)
                console.log(vnode)
            }, 1000)
        }
    }

    // Cancel Timeout
    let cancel = (e) => {
        // Check if timer has a value or not
        if (pressTimer !== null) {
            clearTimeout(pressTimer)
            pressTimer = null
        }
    }
    // Run Function
    const handler = (e) => {
        binding.value(e)
    }

    // Add Event listeners
    el.addEventListener("mousedown", start);
    el.addEventListener("touchstart", start);
    // Cancel timeouts if this events happen
    el.addEventListener("click", cancel);
    el.addEventListener("mouseout", cancel);
    el.addEventListener("touchend", cancel);
    el.addEventListener("touchcancel", cancel);
};