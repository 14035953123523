import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66bdf1ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: _normalizeClass(_ctx.inputClass ? _ctx.inputClass : ''),
      value: _ctx.localInput,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localInput = $event.target.value)),
      id: "google_autocomplete_province",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteText())),
      autocomplete: "off"
    }, null, 42, _hoisted_2), [
      [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue}]
    ])
  ]))
}