
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import { BackofficeLocalizationClient, LocalizationClient } from './services/Services';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    loaded: boolean = false;

    @Watch("$store.state.loggedUser.preferredCulture")
    languageChanged() {
        if(store.state.consts.localizedValues.length == 0)
            return;

        store.commit("updatedLanguage")
    }

    created() {
        store.state.loggedUser = StorageServices.getLoggedUser();
        store.state.selectedLanguage = store.state.loggedUser.preferredCulture;

        Promise.all([
            BackofficeLocalizationClient.getAppDictionary(),
            LocalizationClient.getEnabledLanguages(),
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
            this.loaded = true;
        })
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}
