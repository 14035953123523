import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'welcome',
            component: () => import("./views/welcome.vue"),
        },
        {
            path: '/?language=en-US',
            redirect: {
                name: 'welcome',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("./views/screensetLogin.vue"),
        },
        {
            path: '/hostess',
            name: 'hostess',
            redirect: "/hostess/login"
        },
        {
            path: '/hostess/login',
            name: 'hostess login',
            component: () => import("./views/hostessLogin.vue"),
        },
        {
            path: '/redirect',
            name: 'redirect',
            component: () => import("./views/welcomeMiddleware.vue"),
        },
    ];
};