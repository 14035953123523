import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5257c2c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label_container" }
const _hoisted_2 = { class: "d-flex mb_10" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "inputContainer" }
const _hoisted_5 = ["type", "placeholder", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["tabContainer", { 'justify-content-end': !_ctx.labelKey }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.labelKey)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: _ctx.labelKey,
              text: _ctx.labelValue
            }, null, 8, ["localizedKey", "text"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (langTab, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["tab", _ctx.selectedLocale == langTab ? 'active' : '']),
            key: i,
            onClick: ($event: any) => (_ctx.setInputLocale(langTab))
          }, _toDisplayString(langTab), 11, _hoisted_3))
        }), 128))
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", { locale: _ctx.selectedLocale }, () => [
        _withDirectives(_createElementVNode("input", {
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInput) = $event)),
          tabindex: _ctx.tabIndex
        }, null, 8, _hoisted_5), [
          [_vModelDynamic, _ctx.selectedInput]
        ])
      ], true)
    ])
  ]))
}